import styles from "../about/about.module.css";
import goa from "../../Assets/Images/Home/goa.jpg";
// import videoOfLeft from "../../Assets/videoplayback (1).mp4";
import videoOfLeft from "../../Assets/working/video.mp4";


const PaisaGoa = () => {
  return (
    <>
      <div className={styles.wrapper}>
        <div
          className={styles.heading}
          style={{
            backgroundImage: `url(${goa})`,
            position: "relative",
            backgroundPosition: "center",
          }}
        >
          <h1 className={styles.headingText}>PaisaGoa.Com</h1>

          <div className={styles.overlay}></div>
        </div>

        <div className={styles.about_Service}>
          <div className={styles.about_text_content}>
            <span className={styles.aboutDesc}>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quaerat
              nostrum repellat molestias quas voluptatibus architecto!
              Voluptatem ut doloremque quae, facere, accusamus beatae sunt in
              quidem accusantium porro molestias asperiores temporibus? Lorem
              ipsum dolor, sit amet consectetur adipisicing elit. Quaerat
              nostrum repellat molestias quas voluptatibus architecto!
              Voluptatem ut doloremque quae, facere, accusamus beatae sunt in
              quidem accusantium porro molestias asperiores temporibus? Lorem
              ipsum dolor, sit amet consectetur adipisicing elit. Quaerat
              nostrum repellat molestias quas voluptatibus architecto!
              Voluptatem ut doloremque quae, facere, accusamus beatae sunt in
              quidem accusantium porro molestias asperiores temporibus?
            </span>
          </div>

          <div className={styles.video}>
            <video width="100%" height="100%" controls>
              <source
                src={videoOfLeft}
                type="video/mp4"
                autoplay
                muted
                controls
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className={styles.ourStoryWrapper}>
          <div className={styles.ourStoryText}>
            <span className={styles.ourStoryHead}>
              {" "}
              Lorem ipsum, dolor sit amet consectetur
            </span>

            <div>
              <ul>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PaisaGoa;
