import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import whatsapp from "./Assets/Images/icons8-whatsapp-48.png";
import "./App.css";

import {
  Home,
  About,
  ContactUs,
  Testimonials,
  Spring,
  PresetPlans,
  JointVenture,
  CapitalClimb,
  Rental,
  PaisaGoa,
  GoaLand,
} from "./Pages";
import { Footer, Navbar } from "./Components";
import Servicess from "./Pages/Services_page/Servicess";
import BusinessModel from "./Pages/BusinessModel/BusinessModel";
import Vlog from "./Components/Vlog/Vlog";

function App() {
  return (
    <div className="Main-con">
      <Router>
        <Navbar />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about" element={<About />} />
          <Route path="/contact" element={<ContactUs />} />
          <Route path="/testimonials" element={<Testimonials />} />
          <Route path="/services" element={<Servicess />} />
          <Route path="/businessModel" element={<BusinessModel />} />
          <Route path="/Spring-services" element={<Spring />} />
          <Route path="/preset" element={<PresetPlans />} />
          <Route path="/jointVenture" element={<JointVenture />} />
          <Route path="/2xcapital" element={<CapitalClimb />} />
          <Route path="/rentals" element={<Rental />} />
          <Route path="/paisaGoa" element={<PaisaGoa />} />
          <Route path="/goaland" element={<GoaLand />} />
          <Route path="/Blog" element={<Vlog />} />
        </Routes>

        <div className="whatsapp">
          <a
            href="https://wa.me/+919302055555?text=Hello!%20I%20would%20like%20to%20know%20more%20about%20your%20services."
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={whatsapp} alt="WhatsApp" />
          </a>
        </div>

        {/* <Upper_Footer /> */}
        <Footer />
      </Router>
    </div>
  );
}

export default App;
