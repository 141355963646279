import React from "react";
import "./Vlog.scss";

import img1 from "../../Assets/blogs/WhatsApp Image 2024-12-07 at 18.50.40_59a0c343.jpg";
import img2 from "../../Assets/blogs/1.jpg";
import img3 from "../../Assets/blogs/3.jpg";
import img4 from "../../Assets/blogs/4.jpg";
import img5 from "../../Assets/blogs/5.jpg";
import img6 from "../../Assets/blogs/6.jpg";

const Vlog = () => {
  const blogPosts = [
    {
      title: "Short Gain: Revolutionizing Real Estate Investment",
      content:
        "At Short Gain, we introduce a unique short-term real estate investment model. Designed to yield fixed returns within a span of 3 to 12 months, this innovative approach empowers investors to participate in predefined projects, ranging from land acquisitions to completed properties like villas. With fixed investment amounts and assured profit margins, our secure mechanism ensures that investors experience clarity and reliability throughout their journey. Discover a world where short-term investments meet maximum assurance.",
      imageUrl: img1,
    },
    {
      title: "Domial Union: A Commitment to Secure and Profitable Real Estate",
      content:
        "Specializing in legally clear and ready-to-sell properties, Domial Union stands out by investing 20% upfront in each project, showcasing our dedication to success. Whether it’s land, villas, or apartments, we handle every aspect of the process, from property selection to sales, all backed by secure bank approvals. Through strategic Joint Development Agreements, we work closely with developers to achieve at least 2x returns on land value, expanding our horizons across states and countries. Join us in building a prosperous future with innovative partnerships and unparalleled market expertise.",
      imageUrl: img2,
    },
    {
      title: "Double Yield: Secure Investments with High Growth Potential",
      content:
        "Double Yield focuses on the acquisition and trading of premium land parcels with clear titles. Partnering with builders through Joint Development Agreements, we secure 2x returns within a span of five years. By targeting land with exceptional growth potential, we provide investors with stable, secure investments backed by legal approvals and tangible assets. Leveraging Goa’s booming real estate market, we present opportunities in land, villas, and apartments to ensure profitability and long-term success.",
      imageUrl: img3,
    },
    {
      title: "Rental Retreat: Hassle-Free Rental Solutions",
      content:
        "At Rental Retreat, we redefine high-end property rentals, offering flexible solutions for luxurious villas and apartments. With market-driven pricing and no lengthy contracts, we focus on tenant satisfaction while delivering maximum investor returns. Our 15+ years of expertise also extend to property management and professional office cleaning services. Whether daily, weekly, or one-off cleaning schedules, we uphold the highest standards. Trust us to manage your property and elevate its value in the competitive real estate landscape.",
      imageUrl: img4,
    },
    {
      title: "paisaGoa.Com: Your Trusted Fund Management Partner",
      content:
        "PaisaGoa.Com specializes in fund management services tailored to the real estate industry. Whether you’re looking to streamline your investments or ensure efficient capital allocation, we offer unparalleled expertise to help you achieve your financial goals. Dive into the world of simplified, strategic fund management with a trusted partner by your side Collaboration for Growth and Success.",
      imageUrl: img5,
    },
    {
      title: "GoaLand Partners: Building Tomorrow Together",
      content:
        "At GoaLand Partners, we believe in the power of partnerships. With our motto, Our Land - Your Development, we provide opportunities for builders to develop our land on a revenue-sharing basis. This unique approach fosters mutual growth and ensures profitable ventures for all stakeholders. Explore the vast potential of Goa’s real estate market with our innovative collaboration model.",
      imageUrl: img6,
    },
  ];

  return (
    <div className="blog-container">
      {/* <h1> Blog</h1> */}
      {blogPosts.map((post, index) => (
        <div
          key={index}
          className={`blog-section ${
            index % 2 === 0 ? "left-right" : "right-left"
          }`}
        >
          <div className="blog-image">
            <img src={post.imageUrl} alt={`Blog Image ${index + 1}`} />
          </div>
          <div className="blog-content">
            <h2>{post.title}</h2>
            <p>{post.content}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Vlog;
