import React from "react";
import { Services } from "../../Components";
import FAQ from "../../Components/FAQ/FAQ";

const data = [
  {
    title:
      "What types of investment opportunities does Goa Realty Partners offer?",
    description:
      "We offer a variety of investment models, including short-term investments with fixed returns, joint ventures through revenue-sharing agreements, and long-term rental mandates for premium properties. Our approach allows investors to choose from flexible, secure options based on their goals and preferences.",
  },
  {
    title: "How does the joint venture model work?",
    description:
      "In our joint venture model, we acquire land and partner with builders on a revenue-sharing basis. We contribute 20% of the project cost upfront, and the builder handles the construction and approvals. Upon completion, the finished property is shared in a pre-defined ratio, typically doubling the value of the land investment. This model minimizes risk for the investor while offering significant returns.",
  },
  {
    title: "Is my investment secure with Goa Realty Partners?",
    description:
      "Absolutely. We prioritize transparency and legal compliance in all our projects. Each property we handle is bank-approved and comes with a clear legal title, ensuring that your investment is safeguarded. Our fixed-return and capital appreciation models are designed with investor security as a top priority.",
  },
  {
    title: "What is the Capital Climb - 2X model?",
    description:
      "The Capital Climb - 2X model is a secure land acquisition strategy where investors can expect a minimum of twice the return on investment within five years. By investing in high-growth land parcels with clear legal titles, this model provides stability and significant appreciation, ideal for investors seeking long-term growth.",
  },
  {
    title:
      "Who can invest with Goa Realty Partners, and is there a minimum investment requirement?",
    description:
      "Our investment opportunities are open to all qualified investors, including individuals, corporations, and NRIs looking to diversify into Goa’s real estate market. The minimum investment requirement varies depending on the project and the model, allowing flexibility for investors of different scales to participate.",
  },
];

export default function BusinessModel() {
  return (
    <div className="mb-5">
      <h2 className="text-center fw-bold mt-5 mb-3">Business Models</h2>
      <Services />

      <div className="mt-5">
        <FAQ data={data} />
      </div>
    </div>
  );
}
