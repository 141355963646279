import React, { useState } from "react";
import "./FAQ.scss";

export default function FAQ(data) {
  const faqData = data.data;

  const [visibleIndex, setVisibleIndex] = useState(null);

  const handleToggle = (index) => {
    console.log(index);
    setVisibleIndex(visibleIndex == index ? null : index);
  };

  return (
    <div className="FAQs_main">
      <h1 className={`heading text-center`}>FAQ's</h1>

      <div className="row faq-row justify-content-center">
        <div className=" first-div">
          {faqData?.map((data, index) => (
            <div
              key={index}
              className="faq-col mb-3 d-flex gap-4"
              onClick={() => handleToggle(index)}
            >
              <div className="inner-faq-col">
                {" "}
                <svg
                  width="25"
                  height="25"
                  fill="currentColor"
                  className={`bi ${
                    visibleIndex === index ? "bi-dash-lg" : "bi-plus-lg"
                  } ${visibleIndex === index ? "rotate-icon" : ""}`}
                  viewBox="0 0 18 18"
                >
                  {visibleIndex === index ? (
                    <path
                      fillRule="evenodd"
                      d="M2 8a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-11A.5.5 0 0 1 2 8z"
                    />
                  ) : (
                    <path
                      fillRule="evenodd"
                      d="M8 2a.5.5 0 0 1 .5.5v5h5a.5.5 0 0 1 0 1h-5v5a.5.5 0 0 1-1 0v-5h-5a.5.5 0 0 1 0-1h5v-5A.5.5 0 0 1 8 2z"
                    />
                  )}
                </svg>
              </div>

              <div
                className={`faq-content ${
                  visibleIndex === index ? "show" : ""
                }`}
              >
                <h4 className={`title`}>{data.title}</h4>
                <p className={`description`}>{data.description}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
