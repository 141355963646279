import React, { useEffect } from "react";
import "./HomeCrousel.scss";
import plan from "../../Assets/Images/Home/wealth-plan.jpg";
import growth from "../../Assets/Images/Home/property-growth.jpg";
import goa from "../../Assets/Images/Home/goa.jpg";
import rental from "../../Assets/Images/Home/property-rental.jpg";
import { useNavigate } from "react-router-dom";
import FirstBanner from "../../Assets/Icon/FirstBanner.jpg";

export default function HomeCrousel() {
  const navigate = useNavigate();

  // auto-scroll carousel
  useEffect(() => {
    const carousel = document.querySelector("#carouselExampleIndicators");
    const bootstrapCarousel = new window.bootstrap.Carousel(carousel, {
      interval: 6000,
      ride: "carousel",
    });
  }, []);

  return (
    <div className="outer-main">
      <div
        id="carouselExampleIndicators"
        className="carousel slide"
        data-bs-pause="false"
      >
        <div className="carousel-indicators">
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="0"
            className="active"
            aria-current="true"
            aria-label="Slide 1"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="1"
            aria-label="Slide 2"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="2"
            aria-label="Slide 3"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="3"
            aria-label="Slide 4"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="4"
            aria-label="Slide 5"
          ></button>
          <button
            type="button"
            data-bs-target="#carouselExampleIndicators"
            data-bs-slide-to="5"
            aria-label="Slide 6"
          ></button>
        </div>

        <div className="carousel-inner">
          <div className="carousel-item active">
            <img src={plan} className="d-block " alt="..." />

            <div className="overlay"></div>

            <div className="details">
              <h1 className="question">
                Ever thought of secured investment in real estate for just a few
                months with guaranteed Pre-Set profits?
              </h1>

              <h4 className="answer">
                With us enjoy Secured & Guaranteed returns on short-term
                investments (3 - 12 Months)
              </h4>

              <div
                className="read-more-container"
                onClick={() => {
                  navigate("/preset");
                }}
              >
                <p className="read-more-text">Read more</p>
              </div>
            </div>
          </div>

          <div className="carousel-item">
            <img src={growth} className="d-block " alt="..." />

            <div className="overlay"></div>

            <div className="details">
              <h1 className="question">
                Are you looking for a high return invest?
              </h1>

              <h4 className="answer">
                With us enjoy Secured & Guaranteed returns on short-term
                investments (3 - 12 Months)
              </h4>

              <div
                className="read-more-container"
                onClick={() => {
                  navigate("/2xcapital");
                }}
              >
                <p className="read-more-text">Read more</p>
              </div>
            </div>
          </div>

          <div className="carousel-item">
            <img src={rental} className="d-block " alt="..." />

            <div className="overlay"></div>

            <div className="details">
              <h1 className="question">
                Are your looking for a monthly-returns from your investment?
              </h1>

              <h4 className="answer">
                Goa is one of highest Rental ROI market in Residential
                Investments
              </h4>

              <div
                className="read-more-container"
                onClick={() => {
                  navigate("/rentals");
                }}
              >
                <p className="read-more-text">Read more</p>
              </div>
            </div>
          </div>

          <div className="carousel-item">
            <img src={goa} className="d-block " alt="..." />

            <div className="overlay"></div>

            <div className="details">
              <h1 className="question">
                Ever thought of secured investment in real estate for just a few
                months with guaranteed Pre-Set profits?
              </h1>

              <h4 className="answer">
                With us enjoy Secured & Guaranteed returns on short-term
                investments (3 - 12 Months)
              </h4>

              <div
                className="read-more-container"
                onClick={() => {
                  navigate("/paisaGoa");
                }}
              >
                <p className="read-more-text">Read more</p>
              </div>
            </div>
          </div>

          <div className="carousel-item">
            <img src={FirstBanner} className="d-block " alt="..." />

            <div className="overlay"></div>

            <div className="details">
              <h1 className="question">
                Are you a builder and willing to enter into Lucrative Goa Market
                ?
              </h1>

              <h4 className="answer">
                We give you land for Joint Venture development
                <br />
                Our Land – Your Development
              </h4>

              <div
                className="read-more-container"
                onClick={() => {
                  navigate("/goaland");
                }}
              >
                <p className="read-more-text">Read more</p>
              </div>
            </div>
          </div>
        </div>

        <button
          className="carousel-control-prev"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="prev"
        >
          <span
            className="carousel-control-prev-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Previous</span>
        </button>

        <button
          className="carousel-control-next"
          type="button"
          data-bs-target="#carouselExampleIndicators"
          data-bs-slide="next"
        >
          <span
            className="carousel-control-next-icon"
            aria-hidden="true"
          ></span>
          <span className="visually-hidden">Next</span>
        </button>
      </div>
    </div>
  );
}
