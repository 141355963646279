import styles from "./home.module.css";
import { Services } from "../../Components";
import "./modal.css";
import "react-toastify/dist/ReactToastify.css";
import { useEffect, useState } from "react";
import Testimonials from "../Testimonial/Testimonials";
import emailjs from "@emailjs/browser";
import { ToastContainer, toast } from "react-toastify";
import HomeCrousel from "../../Components/HomeCrousel/HomeCrousel";
import ContactUs from "../ContactUs/ContactUs";
import AboutUs from "../../Components/AboutUs/AboutUs";
import siderIme from "../../Assets/LatestImage/sider.jpg";
import { IoIosCloseCircle } from "react-icons/io";
import Currentwork from "../../Components/Currentwork/Currentwork";
import Pastwork from "../../Components/Pastwork/Pastwork";

const data = [
  {
    title:
      "What types of investment opportunities does Goa Realty Partners offer?",
    description:
      "We offer a variety of investment models, including short-term investments with fixed returns, joint ventures through revenue-sharing agreements, and long-term rental mandates for premium properties. Our approach allows investors to choose from flexible, secure options based on their goals and preferences.",
  },
  {
    title: "How does the joint venture model work?",
    description:
      "In our joint venture model, we acquire land and partner with builders on a revenue-sharing basis. We contribute 20% of the project cost upfront, and the builder handles the construction and approvals. Upon completion, the finished property is shared in a pre-defined ratio, typically doubling the value of the land investment. This model minimizes risk for the investor while offering significant returns.",
  },
  {
    title: "Is my investment secure with Goa Realty Partners?",
    description:
      "Absolutely. We prioritize transparency and legal compliance in all our projects. Each property we handle is bank-approved and comes with a clear legal title, ensuring that your investment is safeguarded. Our fixed-return and capital appreciation models are designed with investor security as a top priority.",
  },
  {
    title: "What is the Capital Climb - 2X model?",
    description:
      "The Capital Climb - 2X model is a secure land acquisition strategy where investors can expect a minimum of twice the return on investment within five years. By investing in high-growth land parcels with clear legal titles, this model provides stability and significant appreciation, ideal for investors seeking long-term growth.",
  },
  {
    title:
      "Who can invest with Goa Realty Partners, and is there a minimum investment requirement?",
    description:
      "Our investment opportunities are open to all qualified investors, including individuals, corporations, and NRIs looking to diversify into Goa’s real estate market. The minimum investment requirement varies depending on the project and the model, allowing flexibility for investors of different scales to participate.",
  },
];

function Home() {
  const [show, setShow] = useState(false);
  const [showSingleBusiness, setShowSingleBusiness] = useState(false);
  const [singleBusiness, setSingleBusiness] = useState({});

  const handleShowBusiness = (showBusiness, businessData) => {
    setShowSingleBusiness(showBusiness);
    setSingleBusiness(businessData);
  };

  const handleShowModal = () => {
    setShowSingleBusiness(!showSingleBusiness);
  };

  const closeBtn = () => {
    setShow(false);
  };

  const options = {
    publicKey: process.env.REACT_PUBLIC_KEY,
    // Do not allow headless browsers
    blockHeadless: true,
    limitRate: {
      // Set the limit rate for the application
      id: "best1_cleaners",
      // Allow 1 request per 10s
      throttle: 1000,
    },
  };

  const [formData, setFormData] = useState({
    email: "",
    message: "",
    phoneNumber: "",
    fullName: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Replace these with your actual email service, template ID, and user ID
    const serviceId = process.env.REACT_APP_SERVICE_ID;
    console.log("serviceId: ", serviceId);
    const templateId = process.env.REACT_APP_TEMPLATE_ID;
    console.log("templateId: ", templateId);

    try {
      await emailjs.send(serviceId, templateId, formData, {
        publicKey: process.env.REACT_APP_PUBLIC_KEY,
      });

      // Clear form data after successful submission
      setFormData({
        email: "",
        phoneNumber: "",
        message: "",
        fullName: "",
      });
      toast.success("Email send Successfully!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  useEffect(() => {
    emailjs.init(options);
  }, []);

  return (
    <>
      <div className={styles.wrapper} style={{ position: "relative" }}>
        {/* crousel */}
        <HomeCrousel />

        {/* SERVICES DESCRIPTION */}
        <div className={styles.aboutServices}>
          <div className={styles.servicesLeft}>
            <span className={styles.leftHead}>Goa Realty Partners</span>
            <p>
              <b>Welcome to Goa Realty Partners</b> where we bring innovative
              real estate opportunities to life within Goa’s vibrant market. Our
              unique investment models empower investors and builders alike to
              achieve secure, high-yield returns with minimal risk. From
              short-term plans with fixed returns to long-term rental mandates,
              our services cater to diverse investment objectives, ensuring
              financial security and transparency. Every project is legally
              sound, bank-approved, and strategically positioned for growth,
              thanks to our deep expertise in land acquisition and development.
              Our unique “Our Land, Your Development” revenue-sharing approach
              invites builders to partner with us, offering an affordable entry
              into Goa’s thriving market. We focus on premium locations—from
              beachside villas to luxury apartments—ensuring high appreciation
              potential for investors. With our commitment to responsible
              property management and seamless end-to-end project oversight,
              clients enjoy maximized returns and peace of mind. Discover the
              potential of Goa real estate with a trusted partner dedicated to
              delivering long-term value and success.
            </p>
          </div>

          <div className={styles.serviceImageContainer}>
            <img
              src={siderIme}
              className={styles.aboutServicesImg}
              alt="services-image"
            />
          </div>
        </div>

        {/* LIST OF SERVICES */}
        <div className={styles.servicesHead}> Business Models </div>
        <Services handleShowBusiness={handleShowBusiness} />
      </div>

      <AboutUs />

      <div className="currentwork">
        <Currentwork />
      </div>
      <div className="currentwork mt-5">
        <Pastwork />
      </div>

      <Testimonials />

      <ContactUs />

      <div
        id="myModal"
        className="modal"
        style={{ display: show ? "block" : "none" }}
      >
        <div className="modal-content">
          <span className="close" onClick={closeBtn}>
            &times;
          </span>
          <form className={styles.contact_form} onSubmit={handleSubmit}>
            <span className={styles.formHeader}>SEND A MESSAGE</span>

            <div className={styles.input_group}>
              <div className={styles.formField}>
                <div className={styles.label}>Full Name</div>
                <input
                  className={styles.formInput}
                  type="text"
                  id="fullName"
                  onChange={handleInputChange}
                  name="fullName"
                  required
                />
              </div>

              <div className={styles.formField}>
                <div className={styles.label}>Email</div>
                <input
                  required
                  className={styles.formInput}
                  type="email"
                  id="email"
                  onChange={handleInputChange}
                  name="email"
                />
              </div>
            </div>
            <div className={styles.formField}>
              <div className={styles.label}>Phone</div>
              <input
                required
                className={styles.formInput}
                type="tel"
                id="phone"
                name="phoneNumber"
                onChange={handleInputChange}
              />
            </div>

            <div className={styles.formField}>
              <div className={styles.label}> Job Description </div>
              <textarea
                className={styles.formInput}
                id="jobDescription"
                name="message"
                rows="4"
                onChange={handleInputChange}
                required
              />
            </div>

            <button className={styles.submitBtn} type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>

      {showSingleBusiness && (
        <div
          className={styles.singleBusinessContainer}
          onClick={handleShowModal}
        >
          <div
            className={styles.mainContainer}
            onClick={(e) => e.stopPropagation()}
          >
            <div className={styles.topContainer}>
              <div className={styles.imageContainer}>
                <img
                  src={singleBusiness?.profileImage}
                  alt={`${singleBusiness?.name} image`}
                  className={styles.businessImage}
                />

                <div className={styles.overlay}></div>

                <div className={styles.titleContainer}>
                  <h1 className={styles.businessName}>
                    {singleBusiness?.name}
                  </h1>
                </div>

                <div className={styles.closeIcon} onClick={handleShowModal}>
                  <IoIosCloseCircle size={30} color={"var(--error)"} />
                </div>
              </div>
            </div>

            <div className={styles.bottomContainer}>
              <div className={styles.aboutSection}>
                {singleBusiness.about.map((aboutText, index) => (
                  <p key={index} className={styles.about}>
                    {aboutText}
                  </p>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}

      {/* <Footer /> */}
      <ToastContainer />
    </>
  );
}

export default Home;
