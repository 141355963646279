import styles from "../about/about.module.css";
import partner from "../../Assets/Images/Home/partner.jpg";
// import videoOfLeft from "../../Assets/videoplayback (1).mp4";
import videoOfLeft from "../../Assets/working/video.mp4";

import FirstBanner from "../../Assets/Icon/FirstBanner.jpg";

const GoaLand = () => {
  return (
    <>
      <div className={styles.wrapper}>
        <div
          className={styles.heading}
          style={{
            backgroundImage: `url(${FirstBanner})`,
            position: "relative",
          }}
        >
          <h1 className={styles.headingText}>GoaLand Partners</h1>

          <div className={styles.overlay}></div>
        </div>

        <div className={styles.about_Service}>
          <div className={styles.about_text_content}>
            <span className={styles.aboutDesc}>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit.
              Exercitationem repellat, enim sunt quisquam excepturi temporibus
              dolorem voluptatum. Atque fuga dolores assumenda saepe, illum est,
              laborum placeat nisi ratione, nobis nam! Lorem ipsum dolor, sit
              amet consectetur adipisicing elit. Exercitationem repellat, enim
              sunt quisquam excepturi temporibus dolorem voluptatum. Atque fuga
              dolores assumenda saepe, illum est, laborum placeat nisi ratione,
              nobis nam! Lorem ipsum dolor, sit amet consectetur adipisicing
              elit. Exercitationem repellat, enim sunt quisquam excepturi
              temporibus dolorem voluptatum. Atque fuga dolores assumenda saepe,
              illum est, laborum placeat nisi ratione, nobis nam!
            </span>
          </div>

          <div className={styles.video}>
            <video width="100%" height="100%" controls>
              <source
                src={videoOfLeft}
                type="video/mp4"
                autoplay
                muted
                controls
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className={styles.ourStoryWrapper}>
          <div className={styles.ourStoryText}>
            <span className={styles.ourStoryHead}>
              {" "}
              Lorem ipsum, dolor sit amet consectetur
            </span>

            <div>
              <ul>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GoaLand;
