import styles from "../about/about.module.css";
import rental from "../../Assets/Images/Home/property-rental.jpg";
// import videoOfLeft from "../../Assets/videoplayback (1).mp4";
import videoOfLeft from "../../Assets/working/video.mp4";


const Rental = () => {
  return (
    <>
      <div className={styles.wrapper}>
        <div
          className={styles.heading}
          style={{
            backgroundImage: `url(${rental})`,
            position: "relative",
            backgroundPosition: "center",
          }}
        >
          <h1 className={styles.headingText}>Rentals</h1>

          <div className={styles.overlay}></div>
        </div>

        <div className={styles.about_Service}>
          <div className={styles.about_text_content}>
            <span className={styles.aboutHead}>
              {" "}
              Long-Term Rental Mandates for Exclusive Premium Properties
            </span>

            <span className={styles.aboutDesc}>
              This model focuses on offering long-term rental mandates for
              exclusive high-end villas and apartments sold to real estate
              investors, featuring distinguished X factors. With comprehensive
              management, market-driven pricing, and a commitment to tenant
              satisfaction, investors can maximize rental income and enjoy
              hassle-free property management. We never bind our existing
              customers or new one in a lengthy contract . We provide office
              cleaning services on a daily , weekly , fortnightly or once- off
              basis hire our office cleaning services in on a daily, weekly or
              one-off basis – no strings attached. And very big thanks to our15+
              years of experience and high standard commercial-grade equipment,
              our teams clean your office to the highest standards. From grimy
              tiles to dusty windows, you can trust us and leave all your
              commercial cleaning needs to us to deliver comprehensive
              commercial cleaning service.
            </span>
          </div>

          <div className={styles.video}>
            <video width="100%" height="100%" controls>
              <source
                src={videoOfLeft}
                type="video/mp4"
                autoplay
                muted
                controls
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className={styles.ourStoryWrapper}>
          <div className={styles.ourStoryText}>
            <span className={styles.ourStoryHead}>
              {" "}
              Lorem ipsum, dolor sit amet consectetur
            </span>

            <div>
              <ul>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Rental;
