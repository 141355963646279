import React from "react";
import "./AboutUs.scss";
import { founderList } from "../../constants/constants";
import secondIme from "../../Assets/LatestImage/second.jpg";

const AboutUs = () => {
  return (
    <section className="about-us-container mt-5">
      <div className="heading-section">
        <h3 className="heading">Team GRP</h3>
      </div>

      <div className="founders-container">
        {founderList.map((item, index) => (
          <div key={index} className="founder-container">
            <div className="left-container">
              <div className="founder-image-container">
                <img
                  src={item.profileImage}
                  alt={`${item.name} image`}
                  className="founder-image"
                />
              </div>
            </div>

            <div className="right-container">
              <div className="primary-details">
                <h5 className="name">{item.name}</h5>
                <p className="headd">{item.head}</p>
                <p className="designation">{item.designation}</p>
              </div>

              <p className="about">{item.about}</p>
            </div>
          </div>
        ))}
      </div>

      <div className="about-company-container">
        <div className="company-image-container">
          <div className="image-container">
            <img
              src={secondIme}
              alt="company image"
              className="company-image"
            />
          </div>
        </div>

        <div className="company-details">
          <div className="company-primary-details">
            <h4 className="subheading">Why Choose Goa realty Partners?</h4>
            {/* 
            <ul>
              {perksList.map((item, index) => (
                <li>
                  <p>{item.perk}</p>
                </li>
              ))}
            </ul> */}
          </div>

          <p className="para">
            With deep expertise in Goa’s real estate market, we identify
            high-potential investment opportunities with maximized returns and
            minimized risks. Our secure investment models offer both
            fixed-return and revenue-sharing options, designed for transparency
            and financial security. Every property is legally sound and
            bank-approved, ensuring peace of mind. Through our unique “Our Land,
            Your Development” approach, we partner with builders, eliminating
            land costs while boosting profit potential. We focus on high-growth
            areas, including premium beachside villas and luxury apartments, and
            handle end-to-end project management, maximizing rental yields and
            providing investors with a seamless experience and passive income.
          </p>
          {/* 
          <p>
            <b>Secure Investment Models</b> From short-term plans with fixed
            returns to long-term revenue-sharing partnerships, each of our
            models is designed with financial security and transparency at its
            core.
          </p>

          <p className="para">
            <b>Clear Legal Framework & Proven Track Record</b> Our properties
            come with legally sound titles and bank approvals, supported by a
            successful track record of completed projects and satisfied clients.
          </p>

          <p>
            <b>Joint Venture Opportunities</b> Our unique “Our Land, Your
            Development” approach invites builders to partner with us, offering
            a revenue-sharing model that eliminates land costs while boosting
            profit potential.
          </p> */}
        </div>
      </div>
      {/* <div className="about-company-details">
        <p>
          <b>Dedicated 20% Upfront Investment</b> We demonstrate our commitment
          by investing 20% upfront in every project, giving our investors
          confidence in our dedication and stake in project success.
        </p>
        <p>
          <b> High-Growth Locations</b> Our projects focus on premium locations
          within Goa that are primed for growth, including beachside villas,
          luxury apartments, and lands with high appreciation potential.
        </p>
        <p>
          <b>End-to-End Management</b> We manage every aspect of the
          project—from sourcing and negotiating to sales and rental
          management—providing a hassle-free experience for investors.
        </p>
        <p>
          <b>Rental Management Model</b> Our exclusive rental management
          services for luxury properties maximize rental yields, allowing
          investors to enjoy passive income with complete property care.
        </p>
        <p>
          <b>Proven Track Record</b> Backed by successful project completions
          and satisfied clients, our reputation speaks to our reliability,
          professionalism, and commitment to investor success.
        </p>
        <p>
          <b>Expanding Network</b> Our reach extends beyond Goa, connecting
          investors from multiple states and countries, allowing for increased
          visibility and growth in diverse real estate markets.
        </p>
      </div> */}
    </section>
  );
};

export default AboutUs;
