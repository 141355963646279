import { useEffect, useState } from "react";
import styles from "./navbar.module.css";
import "./Navbar.scss";
import Logo from "../../Assets/Images/gjp.png";
import newlogo from "../../Assets/latestlogo.png";
import { useLocation, useNavigate } from "react-router-dom";
import { FaPhone, FaTimes } from "react-icons/fa";
import whatsapp from "../../Assets/Images/icons8-whatsapp-48.png";

const Hamburger = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="30"
    height="24"
    viewBox="0 0 52 24"
  >
    <g id="Group_9" data-name="Group 9" transform="translate(-294 -47)">
      <rect
        id="Rectangle_3"
        data-name="Rectangle 3"
        width="42"
        height="4"
        rx="2"
        transform="translate(304 47)"
        fill="#574c4c"
      />
      <rect
        id="Rectangle_5"
        data-name="Rectangle 5"
        width="42"
        height="4"
        rx="2"
        transform="translate(304 67)"
        fill="#574c4c"
      />
      <rect
        id="Rectangle_4"
        data-name="Rectangle 4"
        width="52"
        height="4"
        rx="2"
        transform="translate(294 57)"
        fill="#574c4c"
      />
    </g>
  </svg>
);

function Navbar() {
  const isActive = (path) => location.pathname === path;
  const location = useLocation();
  const navigate = useNavigate();
  const [show, setShow] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  const [isScrolledDown, setIsScrolledDown] = useState(false);

  const handleShowNavbar = () => {
    setShowNavbar(!showNavbar);
  };

  const handleModal = () => {
    console.log("Click modal");
    setShow(true);
  };

  useEffect(() => {
    let prevScrollPos = window.pageYOffset;
    const handleScroll = () => {
      const currentScrollPos = window.pageYOffset;
      const isScrollingDown = currentScrollPos != 0;
      setIsScrolledDown(isScrollingDown);
      prevScrollPos = currentScrollPos;
      console.log(isScrolledDown);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <nav className={styles.navbar}>
      <div className={styles.container}>
        <div className={styles.combo}>
          <div className={styles.logoContainer}>
            <img
              src={newlogo}
              alt="logo"
              className={styles.logo}
              onClick={() => navigate("/")}
            />
          </div>
          <a className={styles.linkcolor} href="tel:+919302055555">
            <FaPhone size={25} />

            <p>+91 9302055555</p>
          </a>
        </div>

        <div className={styles.menu_icon} onClick={handleShowNavbar}>
          <Hamburger />
        </div>

        {/* <div className={styles.nambarr}>
          <ul
            className={`list-unstyled fw-bold gap-4  ${styles.tablist}`}
            style={{ cursor: "pointer" }}
          >
            <li className={styles.tab} onClick={() => navigate("/")}>
              Home{" "}
            </li>

            <li
              className={styles.tab}
              onClick={() => navigate("/businessModel")}
            >
              Business Models{" "}
            </li>
            <li className={styles.tab} onClick={() => navigate("/about")}>
              About Us{" "}
            </li>
            <li className={styles.tab} onClick={() => navigate("/contact")}>
              Contact{" "}
            </li>
          </ul>
        </div> */}
        <div className={styles.nambarr}>
          <ul
            className={`list-unstyled fw-bold gap-4 ${styles.tablist}`}
            style={{ cursor: "pointer" }}
          >
            <li
              className={styles.tab}
              style={{ color: isActive("/") ? "red" : "black" }}
              onClick={() => navigate("/")}
            >
              Home
            </li>

            <li
              className={styles.tab}
              style={{ color: isActive("/businessModel") ? "red" : "black" }}
              onClick={() => navigate("/businessModel")}
            >
              Business Models
            </li>

            <li
              className={styles.tab}
              style={{ color: isActive("/about") ? "red" : "black" }}
              onClick={() => navigate("/about")}
            >
              About Us
            </li>

            <li
              className={styles.tab}
              style={{ color: isActive("/contact") ? "red" : "black" }}
              onClick={() => navigate("/contact")}
            >
              Contact
            </li>
          </ul>
        </div>
      </div>

      <div className={`${styles.nav_elements} ${showNavbar && styles.active}`}>
        <div
          className={styles.closeIcon}
          onClick={() => setShowNavbar(!showNavbar)}
        >
          <FaTimes />
        </div>

        <div className={styles.btnContainer}>
          <ul className="list-unstyled fw-bold">
            <li
              className="mt-2"
              onClick={() => {
                navigate("/");
                setShowNavbar(!showNavbar);
              }}
            >
              Home{" "}
            </li>

            <li
              className="mt-3"
              onClick={() => {
                navigate("/businessModel");
                setShowNavbar(!showNavbar);
              }}
            >
              Business Models{" "}
            </li>
            <li
              className="mt-3"
              onClick={() => {
                navigate("/about");
                setShowNavbar(!showNavbar);
              }}
            >
              About Us{" "}
            </li>

            <li
              className="mt-3"
              onClick={() => {
                navigate("/contact");
                setShowNavbar(!showNavbar);
              }}
            >
              Contact Us
            </li>
          </ul>
        </div>
      </div>

      {showNavbar && (
        <div
          className={styles.sidebarOverlay}
          onClick={() => setShowNavbar(!showNavbar)}
        />
      )}
    </nav>
  );
}

export default Navbar;
