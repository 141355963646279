import { useState } from "react";
import styles from "./Services.module.css";
import servicesData from "../../Assets/Data/services";
import { Link, useNavigate } from "react-router-dom";
import "./Service.scss";

import secodnImage from "../../Assets/LatestImage/2ndPhoto.jpg";
import thirdPhoto from "../../Assets/LatestImage/3rdImage.jpg";

import img1 from "../../Assets/Images/Services/prewealth.avif";
import img2 from "../../Assets/Images/Services/Jointventure.avif";
import img3 from "../../Assets/Images/Services/2xcapital.avif";
import img4 from "../../Assets/Images/Services/rental.avif";
import img5 from "../../Assets/Images/Services/pasagoa.avif";
import img6 from "../../Assets/Images/Services/landparnar.jpg";

import icon1 from "../../Assets/Icon/1st.png";
import icon2 from "../../Assets/Icon/2nd.png";
import icon3 from "../../Assets/Icon/3rd.png";
import x2 from "../../Assets/Icon/2xnew.png";
import icon4 from "../../Assets/Icon/4th.png";
import icon5 from "../../Assets/Icon/5th.png";
import icon6 from "../../Assets/Icon/6th.png";

import plan from "../../Assets/Images/Home/wealth-plan.jpg";
import venture from "../../Assets/Images/Home/joint-venture.jpg";
import growth from "../../Assets/Images/Home/capital-climb.png";
import goa from "../../Assets/Images/Home/goa.jpg";
import partner from "../../Assets/Images/Home/partner.jpg";

const founderList = [
  {
    id: 0,
    name: "Short Gain",
    designation: "",
    profileImage: plan,
    profileImage: icon1,
    about: [
      "Our real estate business introduces a short-term investment concept, designed to offer fixed returns within a 3-12 month timespan. Investors commit to a predefined project—whether land or finished properties like villas—where both the investment amount and profit margins are fixed in advance. With a secure mechanism in place, ",
      "this model ensures a clear and reliable opportunity for investors seeking ",
      "assured returns within a set timeframe.",
    ],
  },
  {
    id: 1,
    name: "Domial Union",
    designation: "",
    profileImage: icon2,
    about: [
      "We specialize in buying and selling legally clear, ready-to-sell properties like land, villas, and apartments, not construction. We invest 20% upfront in each project to showcase our commitment and handle everything from property selection to sales with secured bank approvals. Through Joint Development Agreements, we collaborate with developers, achieving at least 2x returns on land value within a set timeframe. Our investor partnerships expand our reach across states and countries, driving sales and future opportunities.",
    ],
  },
  {
    id: 2,
    name: "Double Yield",
    designation: "",
    profileImage: x2,
    about: [
      "This model focuses on trading land with clear titles or partnering with builders under Joint Development Agreements. By acquiring premium land parcels with high growth potential, the approach ensures a 2x return within 5 years, backed by tangible assets and legal approvals. Investors benefit from secure, stable investments without operational involvement, leveraging Goa’s booming real estate market. Opportunities include land, villas, and apartments for profitable ventures.",
    ],
  },
  {
    id: 3,
    name: "Rental Retreat",
    designation: "",
    profileImage: icon4,
    about: [
      "This model provides flexible, hassle-free rental solutions for high-end villas and apartments, maximizing investor returns. With no lengthy contracts, we prioritize tenant satisfaction and market-driven pricing. Our 15+ years of expertise extend to comprehensive office cleaning services on daily, weekly, or one-off schedules. Trust us for premium property management and high-standard cleaning services. Explore opportunities in exclusive villas and apartments.",
    ],
  },
  {
    name: "PaisaGoa.Com",
    designation: "",
    profileImage: icon5,
    about: ["Fund Management Services"],
  },
  {
    name: "GoaLand Partners",
    designation: "",
    profileImage: icon6,
    about: [
      "Our Land - Your Development",

      "Prospects : Builders to developer our land on revenue sharing basis",
    ],
  },
];

function ServiceCard({ serviceName, imgURL, Icon, link }) {
  const navigate = useNavigate();

  return (
    <div className={styles.servicardMain}>
      <Link
        to={link}
        className={styles.cardWrapper}
        style={{ backgroundImage: `url(${imgURL})`, contain: "fit" }}
        onClick={() => window.scrollTo(0, 0)} // Scroll to top when clicked
      >
        <Icon className={styles.serviceLogo} />
        <span className={styles.serviceName}>{serviceName}</span>
      </Link>

      <button
        onClick={() => {
          navigate(link);
          window.scrollTo(0, 0);
        }}
      >
        Learn More...
      </button>
    </div>
  );
}

function Services({ handleShowBusiness }) {
  const [isExpanded, setIsExpanded] = useState(false);
  const isMobile = window.innerWidth <= 768;
  const [showModal, setShowModal] = useState(false);
  const [showIndex, setShowIndex] = useState(-1);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className={styles.wrapper}>
      <div className="service-container">
        
        {founderList.map((item, index) => (
          <div key={index} className="inner-container">
            <div className="left-container">
              <div className="founder-image-container">
                <img
                  src={item.profileImage}
                  alt={`${item.name} image`}
                  className="founder-image"
                />
              </div>
            </div>

            <div className="right-container">
              <div className="primary-details">
                <h5 className="name">{item.name}</h5>
              </div>

              <div className="about-content">
                {item.about.map((aboutText, index) => (
                  <p key={index} className="about-paragraph">
                    {aboutText}
                  </p>
                ))}
              </div>

              {item.id >= 0 && (
                <div
                  className="read-more-container"
                  onClick={() => {
                    handleShowBusiness(true, founderList[item.id]);
                  }}
                >
                  <p className="read-more-text">Read more...</p>
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default Services;
