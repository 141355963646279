import styles from "../about/about.module.css";
import growth from "../../Assets/Images/Home/property-growth.jpg";
// import videoOfLeft from "../../Assets/videoplayback (1).mp4";
import videoOfLeft from "../../Assets/working/video.mp4";


const CapitalClimb = () => {
  return (
    <>
      <div className={styles.wrapper}>
        <div
          className={styles.heading}
          style={{
            backgroundImage: `url(${growth})`,
            position: "relative",
            backgroundPosition: "center",
          }}
        >
          <h1 className={styles.headingText}>2x Capital Climb</h1>

          <div className={styles.overlay}></div>
        </div>

        <div className={styles.about_Service}>
          <div className={styles.about_text_content}>
            <span className={styles.aboutHead}>
              {" "}
              Capital Climb - 2X: Secure Land Investments with Guaranteed Growth
            </span>

            <br />

            <span className={styles.aboutDesc}>
              This model specializes in trading land with clear legal titles or
              partnering with builders for development projects under Joint
              Development Agreements. The focus is on acquiring land, which is
              either traded for profit or developed by a builder who manages all
              approvals and construction at their own cost. In return, the
              finished property is received by us in a pre-defined ratio,
              typically yielding a minimum of 2x the value of the land within a
              pre-defined period. Additionally, 20% of the initial project cost
              is contributed from the company’s side to demonstrate commitment
              to each venture.
            </span>
          </div>

          {/* <img src={randomImg} className={styles.aboutImg} alt="" /> */}
          <div className={styles.video}>
            <video width="100%" height="100%" controls>
              <source
                src={videoOfLeft}
                type="video/mp4"
                autoplay
                muted
                controls
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className={styles.ourStoryWrapper}>
          <div className={styles.ourStoryText}>
            <span className={styles.ourStoryHead}>
              {" "}
              Lorem ipsum, dolor sit amet consectetur
            </span>

            <div>
              <ul>
                <li>
                  With Capital Climb - 2X, retail investors have the opportunity
                  to invest securely. In this model, investments are used
                  exclusively for acquiring premium land parcels, which are
                  carefully selected for high growth potential. The team ensures
                  that the land has clear titles and legal approvals, with bank
                  support in place for each project.
                </li>
                <li>
                  Investors enjoy an unconditional 2x return on investment
                  within 5 years, secured against tangible land assets. The
                  proven model of successful land trading and joint development
                  minimizes risk, while investor participation helps expand the
                  network across India and beyond, unlocking wider sales
                  opportunities.
                </li>
                <li>
                  This model is suitable for investors looking to diversify
                  their portfolios or invest in a stable, high-growth real
                  estate market, providing a secure path to double investments
                  in just 5 years without any operational engagement. It
                  capitalizes on Goa’s booming real estate market with
                  confidence, ensuring all investments are safe and yield
                  guaranteed returns.
                </li>
                <li>
                  Business Opportunities on Floor Land ,Villa & Apartments
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CapitalClimb;
