import styles from "../about/about.module.css";
import plan from "../../Assets/Images/Home/wealth-plan.jpg";

const PresetPlans = () => {
  return (
    <>
      <div className={styles.wrapper}>
        <div
          className={styles.heading}
          style={{
            backgroundImage: `url(${plan})`,
            position: "relative",
            backgroundPosition: "center",
          }}
        >
          <h1 className={styles.headingText}>Preset Wealth Plan </h1>

          <div className={styles.overlay}></div>
        </div>

        <div className={styles.about_Service}>
          <div className={styles.about_text_content}>
            <p className={styles.aboutDesc}>
              Our real estate business introduces a short-term investment
              concept, designed to offer fixed returns within a 3-12 month
              timespan. Investors commit to a predefined project—whether land or
              finished properties like villas—where both the investment amount
              and profit margins are fixed in advance. With a secure mechanism
              in place, this model ensures a clear and reliable opportunity for
              investors seeking assured returns within a set timeframe.
            </p>
          </div>

          <div className={styles.video}>
            {/* <video width="100%" height="100%" controls>
              <source
                src={videoOfLeft}
                type="video/mp4"
                autoplay
                muted
                controls
              />
              Your browser does not support the video tag.
            </video> */}

            <img
              src={plan}
              className={styles.aboutServicesImg}
              alt="services-image"
            />
          </div>
        </div>

        <div className={styles.ourStoryWrapper}>
          <div className={styles.ourStoryText}>
            <span className={styles.ourStoryHead}>
              {" "}
              Lorem ipsum, dolor sit amet consectetur
            </span>

            <div>
              <ul>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PresetPlans;
