import styles from "../about/about.module.css";
import venture from "../../Assets/Images/Home/joint-venture.jpg";
// import videoOfLeft from "../../Assets/videoplayback (1).mp4";
import videoOfLeft from "../../Assets/working/video.mp4";

const JointVenture = () => {
  return (
    <>
      <div className={styles.wrapper}>
        <div
          className={styles.heading}
          style={{
            backgroundImage: `url(${venture})`,
            position: "relative",
            backgroundPosition: "center",
          }}
        >
          <h1 className={styles.headingText}>Joint Venture</h1>

          <div className={styles.overlay}></div>
        </div>

        <div className={styles.about_Service}>
          <div className={styles.about_text_content}>
            <span className={styles.aboutDesc}>
              We are not involved in construction. Instead, we focus on buying
              and selling properties that have clear legal titles and are ready
              for sale, such as land, villas, or apartments. <br />
              <br />
              When we take on a project, we invest 20% of the total project cost
              upfront to show our confidence & commitment. From there, we manage
              everything—from identifying the right property to completing the
              deal and making sales. We always secure bank approvals for the
              project, ensuring it is financially sound. <br />
              <br />
              We trade the land or, alternatively, partner with a builder on a
              Joint Development Agreement. In this case, the developer handles
              all approvals and construction at their cost, and we share the
              finished property. This model typically delivers a minimum 2x
              revenue compared to the original land value, within a pre-defined
              timeframe. <br />
              <br />
              Additionally, we invite investors to join us, which allows us to
              expand our network to multiple states and countries. This wider
              reach helps drive sales and opens doors for future projects.{" "}
              <br />
              <br />
              This model is ideal for anyone looking to invest in Goa’s real
              estate market, with the added assurance that we are equally
              invested in the success of every project.
            </span>
          </div>

          {/* <img src={randomImg} className={styles.aboutImg} alt="" /> */}
          <div className={styles.video}>
            <video width="100%" height="100%" controls>
              <source
                src={videoOfLeft}
                type="video/mp4"
                autoplay
                muted
                controls
              />
              Your browser does not support the video tag.
            </video>
          </div>
        </div>

        <div className={styles.ourStoryWrapper}>
          <div className={styles.ourStoryText}>
            <span className={styles.ourStoryHead}>
              {" "}
              Lorem ipsum, dolor sit amet consectetur
            </span>

            <div>
              <ul>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
                <li>
                  Lorem ipsum odor amet, consectetuer adipiscing elit. Natoque
                  sit est fusce nascetur fames curabitur.
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default JointVenture;
